"use strict";
document.addEventListener("DOMContentLoaded", function() {

	// animate with scrollmotion
	const scrollElements = document.querySelectorAll(".a-scroll");

	scrollElements.forEach((el) => {
		el.style.opacity = 0
	})

	const elementInView = (el, percentageScroll = 100) => {
		const elementTop = el.getBoundingClientRect().top;
		return (
			elementTop <=
			((window.innerHeight || document.documentElement.clientHeight) * (percentageScroll/100))
		);
	};

	const displayScrollElement = (element) => {
		element.classList.add("animated", element.getAttribute("data-a-scroll"));
	};

	const hideScrollElement = (element) => {
		element.classList.remove("animated", element.getAttribute("data-a-scroll"));
	};

	const handleScrollAnimation = () => {
		scrollElements.forEach((el) => {
			if (elementInView(el, 100)) {
				displayScrollElement(el);
			} else {
				// hideScrollElement(el);
			}
		})
	}

	// initialize throttleTimer as false
	let throttleTimer = false;
	const throttle = (callback, time) => {
		//don't run the function while throttle timer is true
		if (throttleTimer) return;

		//first set throttle timer to true so the function doesn't run
		throttleTimer = true;

		setTimeout(() => {
			//call the callback function in the setTimeout and set the throttle timer to false after the indicated time has passed
			callback();
			throttleTimer = false;
		}, time);
	}

	window.addEventListener('scroll', () => {
		throttle(handleScrollAnimation, 250);
	})

	// glider home
	/*
		if (document.getElementById('newpodcasts')) {
			new Glide('.multi', {
				type: 'carousel',
				startAt: 0,
				perView: 4,
				gap: "50",
				animationDuration: 300,
				breakpoints: {
					4000: {
						perView: 8,
						type: 'carousel',
					},
					2600: {
						perView: 6,
						type: 'carousel',
					},
					1600: {
						perView: 4,
						type: 'carousel',
					},
					1200: {
						gap: 30,
						type: 'carousel',
					},
					690: {
						type: 'carousel',
						gap: 0,
						perView: 1,
						startAt: 0,
						focusAt: "center",
					}
				}
			}).mount();
		}
	*/

	const glider = document.querySelector('#glide');
	if (glider){
		const slider = new Glide('#glide', {
			type: 'carousel',
			startAt: 0,
			perView: 1,
			gap: 0,
			animationDuration: 500,
			autoplay: 5000,
			hoverpause: false,
		});
		slider.mount();
	}

	const glidersub = document.querySelector('#glidesub');
	if (glidersub){
		const slidesub = new Glide('#glidesub', {
			type: 'carousel',
			startAt: 0,
			perView: 1,
			gap: 0,
			animationDuration: 500,
			autoplay: 5000,
			hoverpause: false,
		});
		slidesub.mount();
	}

	// get url variables and set anfrage checkbox
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const anfrage = urlParams.get('anfrage');

	if (["1","2","3","4"].indexOf(anfrage) > -1) {
		const anfrage_checkbox = document.querySelector('#id-anfrage-' + anfrage);
		anfrage_checkbox.checked = true;

	}

	// replace e-mail
	let emlArray = document.querySelectorAll("span.eml");
	[...emlArray].forEach((eml) => {
		// console.log(eml);
		let domain = '@palazzo-comacio.ch';
		let name = eml.textContent;
		let cssClass = '';
		if (eml.hasAttribute('data-class')) { cssClass = ' class="' + eml.getAttribute('data-class') + '"'; }
		eml.innerHTML = '<a href="mailto:'+ name + domain +'" '+ cssClass +'>'+ name + domain +'</a>';
	});


	// navbar
	const iconIcon = document.querySelector('.menu-flex');
	const iconMenu = iconIcon.querySelector('.menu-icon');
	const iconText = iconIcon.querySelector('.menu-icon-text');
	const menuBody = document.querySelector('.menu-body');
	const menuBodyItems = document.querySelector('.menu-body .menu-list');

	if (iconIcon) {
		iconIcon.addEventListener("click", function () {
			document.body.classList.toggle('_lock');
			iconMenu.classList.toggle('_active');
			menuBody.classList.toggle('_active');
			if(iconText.innerText === iconText.getAttribute('data-open')){
				iconText.innerText = iconText.getAttribute('data-close');
			} else{
				iconText.innerText = iconText.getAttribute('data-open');
			}
		});
	}
	// ausblenden wenn menupunkt gewählt wurde (anchor)
	if (menuBodyItems) {
		menuBodyItems.addEventListener("click", function () {
			menuBody.classList.toggle('_active');
		});
	}

	// navbar scroll
	const nav = document.querySelector("#navbar-page");
	const navTop = document.getElementById('hero').offsetHeight;

	function fixnavbar(){
		if(window.scrollY >= navTop){
			document.body.style.paddingTop = (nav.offsetHeight - 100) + "px";
			document.body.classList.add("fixed-nav");
		}else {
			document.body.style.paddingTop = 0;
			document.body.classList.remove("fixed-nav");
		}
	}
	window.addEventListener("scroll", fixnavbar);

	// load js file
	const loadScript = (FILE_URL, async = true, type = "text/javascript") => {
		return new Promise((resolve, reject) => {
			try {
				const scriptEle = document.createElement("script");
				scriptEle.type = type;
				scriptEle.async = async;
				scriptEle.src =FILE_URL;

				scriptEle.addEventListener("load", (ev) => {
					resolve({ status: true });
				});

				scriptEle.addEventListener("error", (ev) => {
					reject({
						status: false,
						message: `Failed to load the script ＄{FILE_URL}`
					});
				});

				document.body.appendChild(scriptEle);
			} catch (error) {
				reject(error);
			}
		});
	};

	// validate recaptcha
	window.addEventListener("load", function(){ // Wait for the page to load
		// sample: https://www.educative.io/answers/how-to-dynamically-load-a-js-file-in-javascript

		"use strict"; // Strict mode for JavaScript
		const form = document.querySelector("#evform") // Get the form

		form.addEventListener("submit", function (event){
			event.preventDefault() // Prevent the default action of the form
			let fields = document.querySelectorAll("#evform .form-control") // Get all the fields
			let valid = true
			/*
				// validate required fields
				for (var i = 0; i < fields.length; i++) {
					fields[i].classList.remove("no-error") // remove the no-error class from all fields
					if (fields[i].value === "") { // if the field is empty
						fields[i].classList.add("has-error")
						// fields[i].nextElementSibling.style.display = "block"
						valid = false
					} else { // If the field is not empty
						fields[i].classList.remove("has-error")
						fields[i].classList.add("no-error")
						// fields[i].nextElementSibling.style.display = "none"
					}
				}
			*/
			if (valid) { // If all the fields are valid
				// document.querySelector(".formfields").style.display = "none"
				const elements = form.elements;
				for (var i = 0, len = elements.length; i < len; ++i) {
					elements[i].readOnly = true;
				}
				document.querySelector("#alert").innerText = "Ihre Anfrage wird übermittelt ..."
				// console.log(valid, fields);

				loadScript("https://www.google.com/recaptcha/api.js?render=6LdM_iUlAAAAACo7JkVuWOzgQ61-xM8Lt9_mFh_j")
				.then( data  => {
					console.log("Script loaded successfully", data);
					grecaptcha.ready(function() {
						grecaptcha.execute('6LdM_iUlAAAAACo7JkVuWOzgQ61-xM8Lt9_mFh_j', {action: 'submit'}).then( function(token) {
							// Add your logic to submit to your backend server here.
							// console.log('recaptcha: ', token);
							const recaptchaToken = document.querySelector("#recaptchaResponse")
							recaptchaToken.setAttribute("value", token);
							form.submit();
							for (var i = 0, len = elements.length; i < len; ++i) {
								elements[i].readOnly = false;
							}
							document.querySelector("#alert").innerHTML = "<b>Danke für Ihre Anfrage!</b><br />Wir werden so rasch als möglich antworten.<br /><br />"
						});
					});
				})
				.catch( err => {
					console.error(err);
				});
			}
		})
	})

});

